import React, {useRef, useEffect, useState} from 'react';
import mapboxgl from 'mapbox-gl';

import TimeBlock from "./components/TimeBlock/TimeBlock";
import TimeTab from "./components/TimeTab/TimeTab";
import Popup from "./components/Popup/Popup";
// import Logo from "./components/Logo/Logo";
import Loading from "./components/Loading/Loading";

import {getPlaces, getTimes} from "./api";
import LanguageChange from "./components/LanguageChange/LanguageChange";
import FullScreenPhoto from "./components/FullScreenPhoto/FullScreenPhoto";
import FullScreenVideo from "./components/FullScreenVideo/FullScreenVideo";

// mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
// mapboxgl.accessToken = "pk.eyJ1IjoiZGRkZGRkYW55YSIsImEiOiJjbHZpMG5xMjIwajNzMnZxb2FrbW1wMnh4In0.HCL8keo5WFgvJw5UcyY_9Q";
mapboxgl.accessToken = "pk.eyJ1IjoiZGRkZGRkYW55YSIsImEiOiJjbHZpMG5xMjIwajNzMnZxb2FrbW1wMnh4In0.HCL8keo5WFgvJw5UcyY_9Q";

function App() {
    // Map
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(75.333);
    const [lat, setLat] = useState(51.708);
    const [zoom, setZoom] = useState(10);

    // Popup
    const [popupState, setPopupState] = useState(false);

    // Data
    const [times, setTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState(null);
    const [places, setPlaces] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState({
        media: []
    });

    // Language
    const [language, setLanguage] = useState("kz")

    // Fullscreen photo
    const [fullScreenPhoto, setFullScreenPhoto] = useState(null)
    const [FullScreenVideo, setFullScreenVideo] = useState(null)


    useEffect(() => {
        // Fetch language
        const language = localStorage.getItem("language")
        if (language) {
            setLanguage(language)
        }
    }, []);

    useEffect(() => {
        // Fetch places and times
        async function fetchData() {
            const times = await getTimes()
            setTimes(times)
            setSelectedTime(times[0].id)

            const places = await getPlaces()
            setPlaces(places)

            // Initialize map
            setTimeout(() => {
                if (map.current) return; // If map already initialized, return
                map.current = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: 'mapbox://styles/ddddddanya/cly713upl00el01pm3cey785v',
                    center: [lng, lat],
                    zoom: 0,
                    pitch: 75
                });

                // Add markers
                for (let i in places) {
                    // If place timeId is equal to the first time id
                    if (places[i].timeId == times[0].id) {
                        console.log(places[i])
                        const el = document.createElement('div');
                        el.className = places[i].type.replace(" ", "")
                        const marker = new mapboxgl.Marker(el)
                            .setLngLat([places[i].coordinates[0], places[i].coordinates[1]])
                            .addTo(map.current);

                        // Add click event listener
                        marker.getElement().addEventListener('click', function() {
                            console.log('Marker clicked!');
                            setPopupState(true)
                            console.log(places[i])
                            setSelectedPlace(places[i])
                        });
                    }
                }

                map.current.easeTo({
                    center: [lng, lat],
                    zoom: zoom,
                    duration: 5000, // Duration in milliseconds
                    easing: (t) => t, // Easing function (linear in this case)
                    essential: true // This animation is essential (not optional)
                });
            }, 100)
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (map.current) {
            map.current.remove();
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/ddddddanya/cly713upl00el01pm3cey785v',
                center: [lng, lat],
                zoom: 0,
                pitch: 75
            });
        }

        places.forEach(place => {
            if (place.timeId === selectedTime) {
                const el = document.createElement('div');
                el.className = el.className = place.type.replace(" ", "")

                const marker = new mapboxgl.Marker(el)
                    .setLngLat([place.coordinates[0], place.coordinates[1]])
                    .addTo(map.current);

                marker.getElement().addEventListener('click', function() {
                    console.log('Marker clicked!', place);
                    setPopupState(true);
                    setSelectedPlace(place);
                });
            }
        });

        if (map.current) {
            map.current.easeTo({
                center: [lng, lat],
                zoom: zoom,
                duration: 5000, // Duration in milliseconds
                easing: (t) => t, // Easing function (linear in this case)
                essential: true // This animation is essential (not optional)
            });
        }

    }, [selectedTime]);

    return (
        <>
            {times.length && places.length ? (
                <div className="App">
                    <div ref={mapContainer} className="map-container"></div>

                    {/* <Logo width={80 * 5 + "px"} height={"80px"}/> */}
                    <LanguageChange/>

                    <Popup onClose={() => setPopupState(false)} title={
                        language === "ru" ? selectedPlace?.title : language === "kz" ? selectedPlace?.titleKz : selectedPlace?.titleEn
                    } state={popupState}>
                        <div style={{marginBottom:40}}>
                        {
                                language === "ru" ? selectedPlace?.description :
                                    language === "kz" ? selectedPlace?.descriptionKz : selectedPlace?.descriptionEn
                            }
                        </div>
                        {selectedPlace.media && (
    <>
        {selectedPlace.media.map((media, index) => {
            const isImage = /\.(jpg|jpeg|png|gif)$/i.test(media);
            const isVideo = /\.(mp4|webm|ogg)$/i.test(media);

            if (isImage) {
                return (
                    <img 
                        key={index} 
                        src={media} 
                        alt="media" 
                        style={{ width: "100%", height: "auto" }} 
                        className="Popup__image" 
                        onClick={() => setFullScreenPhoto(media)} 
                    />
                );
            } else if (isVideo) {
                return (
                    <video 
                        key={index} 
                        src={media} 
                        controls 
                        style={{ width: "100%", height: "auto" }} 
                        className="Popup__video" 
                        onClick={() => setFullScreenVideo(media)} 
                    />
                );
            } else {
                return null; // или можно отобразить placeholder для неподдерживаемых типов файлов
            }
        })}
    </>
)}

                    </Popup>

                    <TimeBlock popupState={popupState}>
                        {times.map((time) => (
                            <TimeTab
                                key={time.id}
                                selected={selectedTime === time.id}
                                onClick={() => setSelectedTime(time.id)}
                            >
                                {language === "ru" ? time.title : language === "kz" ? time.titleKz : time.titleEn}
                            </TimeTab>
                        ))}
                    </TimeBlock>

                    {fullScreenPhoto && (
                        <FullScreenPhoto photo={fullScreenPhoto} onClose={() => setFullScreenPhoto(null)}/>
                    )}
                </div>
            ) : (
                <Loading/>
            )}
        </>
    );
}

export default App;
